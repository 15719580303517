
import { defineComponent, onMounted } from 'vue';

import Title from '@/components/Title.vue';
import { useReveal } from '@/utils';

export default defineComponent({
  components: { Title },
  setup() {
    const reveal = useReveal();

    onMounted(() => reveal())
  },
});
